import {Component} from '@angular/core';
import {Message} from "./models/models";
import * as io from "socket.io-client";
import {LogService} from "./services/logger";
import { Md5 } from 'ts-md5/dist/esm/md5';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  readonly WRONG_CREDENTIALS_MSG =   "Wrong login or password!";
  readonly LOGIN_TAKEN_MSG =   "The login is already registered!";
  readonly SUCCESS_MSG =   "SUCCESS!";

  title = 'FrontendApp';
  isLogged = false;
  username = '';
  password = '';
  userID = -1;
  inRegistration = false;
  newUsername = '';
  newPassword = '';
  newPasswordConfirmation = '';
  cols: any;
  userDTO = {login: this.username, password: this.password}
  messages: Message[] = [];
  newMessageText = '';

  // @ts-ignore
  private socketio: io.Socket;

  constructor(private _logger: LogService) {
  }

  ngOnInit() {
    this.connect();
    this.receiveSignInResult();
    this.receiveSignUpResult();
    this.listenForMessages();

    this.cols = [
      {field: 'date', header: 'Date'},
      {field: 'name', header: 'User'},
      {field: 'text', header: 'Text'},
    ];
  }

  register() {

    this.username = this.newUsername;
    this.password = this.newPassword;
    this.signUp()
  }

  login() {
    this.signIn()
  }

  send() {
    this.sendMessage()
    this.newMessageText = '';
  }

  private connect() {
    this.socketio = io.io('http://10.90.137.189:5052'); // 5052 is the port of nginx reverse proxy for backend
  }

  signUp() {
    this._logger.log(`${this.username} tries to sign up`);
    this.socketio.emit('sign-up', {login: this.username, password: Md5.hashStr(this.password)});
  }

  signIn() {
    this._logger.log(`${this.username} tries to sign in`);
    this.socketio.emit('sign-in', {login: this.username, password: Md5.hashStr(this.password)});
  }


  receiveSignInResult() {
    this.socketio.on('sign-in-result', (response) => {
      if (response.result == false) {
        this._logger.error(this.WRONG_CREDENTIALS_MSG);
        alert(this.WRONG_CREDENTIALS_MSG);
        return;
      }
      this._logger.log(this.SUCCESS_MSG);
      this.isLogged = response.result;
      this.userID = response.userID;
    });
  }


  receiveSignUpResult() {
    this.socketio.on('sign-up-result', (response) => {
      if (response.result == false) {
        this._logger.error(this.LOGIN_TAKEN_MSG);
        alert("The login is already registered!");
        return;
      }
      this._logger.log(this.SUCCESS_MSG);
      this.isLogged = response.result;
      this.userID = response.userID;
      this.inRegistration = false;
    });
  }


  listenForMessages() {
    this.socketio.on('new-message', (response) => {
      if (!this.isLogged) {
        return
      }
      this.messages.push(response)
    });
  }

  sendMessage() {
    this._logger.log(`${this.username} sends new message`);
    const messageDTO = {name: this.username, text: this.newMessageText, date: new Date()}
    this.socketio.emit('new-message', messageDTO);
  }

}
