<div *ngIf="inRegistration">
  <div class="login-container">
    <h1> Login now!</h1>
    <span class="p-float-label">
    <input id="new-username-input" type="text" pInputText [(ngModel)]="newUsername">
    <label for="new-username-input">Username</label>

</span>
    <span class="p-float-label">
    <input id="new-password-input" type="password" pInputText [(ngModel)]="newPassword">
    <label for="new-password-input">Password</label>

</span>
    <span class="p-float-label">
    <input id="confirm-new-password-input" type="password" pInputText [(ngModel)]="newPasswordConfirmation">
    <label for="confirm-new-password-input">Password Confirmation</label>

</span>
    <button pButton type="button" label="Back" (click)="inRegistration = false" ></button>
    <button pButton type="button" label="Register" (click)="register()"
            [disabled]="newPassword!=newPasswordConfirmation ||  newPassword.length == 0 || newUsername.length == 0"></button>
  </div>
</div>

<div *ngIf="!isLogged && !inRegistration">
  <div class="login-container">
    <h1> Login now!</h1>
    <span class="p-float-label">
    <input id="username-input" type="text" pInputText [(ngModel)]="username">
    <label for="username-input">Username</label>

</span>
    <span class="p-float-label">
    <input id="password-input" type="password" pInputText [(ngModel)]="password">
    <label for="password-input">Password</label>
</span>

    <button pButton type="button" label="Login"  (click)="login()"
            [disabled]="username.length == 0|| password.length ==0" ></button>
    <button pButton type="button" label="Register" (click)="inRegistration = true"></button>
  </div>
</div>

<div *ngIf="isLogged">
  <div class="chat-container">


  <p-table [columns]="cols" [value]="messages" [tableStyle]="{'min-width': '50rem'}">
    <ng-template pTemplate="body" let-product>
      <tr>
        <p>{{product.date.toLocaleString()}} {{product.user}} : {{product.text}}</p>
      </tr>
    </ng-template>
  </p-table>
  <div class="new-message-container" >
    <input id="new-message" type="text" pInputText class="new-message-input" [(ngModel)]="newMessageText"
    (keydown.enter)="send()">
    <button pButton type="button" label="💬" class="send-button" (click)="send()"></button>
  </div>
  </div>
</div>
